import Layout from "../components/layout";
import React from "react";
import SEO from "../components/seo";
import { Button, Container, Grid, TextField, FormControlLabel, Checkbox, RadioGroup, Radio, Typography, FormHelperText } from "@mui/material";
import { useForm } from "react-hook-form"
import MagnifyingGlass from "../components/icon/magnifying_glass.png"
import theme from "../gatsby-theme-material-ui-top-layout/theme";

let protocolOptions = [
  { title: 'AMM / DEX', value: 'AMM / DEX' },
  { title: 'Basic yield farm (similar to Uniswap, Sushi, Pancake, Panther, etc.)', value: 'Basic yield farm (similar to Uniswap, Sushi, Pancake, Panther, etc.)' },
  { title: 'Customised MasterChef', value: 'Customised MasterChef' },
  { title: 'Staking', value: 'Staking' },
  { title: 'Reflective token (has fees on transfer)', value: 'Reflective token (has fees on transfer)' },
  { title: 'Regular token (no fees on transfer)', value: 'Regular token (no fees on transfer)' },
  { title: 'Lending protocol', value: 'Lending protocol' },
  { title: 'Stableswap', value: 'Stableswap' },
  { title: 'NFT', value: 'NFT' },
  { title: 'Other', value: 'Other' },
]

let networkOptions = [
  { title: 'Ethereum', value: 'Ethereum' },
  { title: 'Aurora (NEAR)', value: 'Aurora (NEAR)' },
  { title: 'Avalanche', value: 'Avalanche' },

  { title: 'Binance Smart Chain', value: 'Binance Smart Chain' },
  { title: 'Fantom', value: 'Fantom' },
  { title: 'HECO', value: 'HECO' },
  { title: 'Oasis (Rose)', value: 'Oasis (Rose)' },
  { title: 'Polygon', value: 'Polygon' },
  { title: 'Other', value: 'Other' },

]

const Contact = () => {


  const [networkValue, setNetworkValue] = React.useState('Ethereum');
  const [selectedProtocols, setSelectedProtocols] = React.useState([]);
  const [isAFork, setIsAFork] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const [submitted, setSubmitted] = React.useState(false);

  const handleNetworkChange = (event) => {
    setNetworkValue(event.target.value);
  };

  const handleProtocolChange = (event) => {
    if (selectedProtocols.indexOf(event.target.value) === -1) {
      setSelectedProtocols(() => [...selectedProtocols, event.target.value])
    } else {
      selectedProtocols.splice(selectedProtocols.indexOf(event.target.value), 1);
    }
  };


  const onIsAForkChange = (event) => {
    setIsAFork(event.target.value);
  };

  // Initiate forms
  const { register, handleSubmit, errors, reset } = useForm()

  // Transforms the form data from the React Hook Form output to a format Netlify can read
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  // Handles the post process to Netlify so we can access their serverless functions
  const handlePost = (formData, event) => {

    setSubmitting(true);
    fetch(`/`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact-form", ...formData }),
    })
      .then((response) => {
        console.log(response)
        reset()
        setSubmitted(true);
      })
      .catch((error) => {
        console.log(error)
      })
    event.preventDefault()
  }

  return (
    <Layout>
      <SEO title="Contact" />
      <Container maxWidth="sm">

        {submitted ?

          <Grid container>

            <Grid textAlign="center" item xs={12}>

              <Typography variant="h4" color="white" fontWeight="bold">Thanks you for<br /> choosing Veracity!</Typography>
              <Typography my={3}

                variant="subtitle1"
                color={theme.palette.primary.main}>We will contact you as soon as possible!</Typography>
            </Grid>

          </Grid>

          :
          <form
            onSubmit={handleSubmit(handlePost)}
            name="contact-form"
            method="POST"
            action="/success/"
            data-netlify="true"
            netlify-honeypot="got-ya"
          >

            <input type="hidden" name="form-name" value="contact-form" />
            <input
              type="hidden"

              value="contact-form"
              {...register('formId')}

            />
            <Grid container spacing={3} my={5} color="white">

              <Grid item xs={12} sm={5}><img style={{ width: "100%" }} src={MagnifyingGlass} alt="Request audit" /></Grid>
              <Grid item xs={12} sm={7}>

                <Typography variant="h4" fontWeight="bold">Request a<br /> Smart Contract<br /> Security Audit</Typography>


              </Grid>
              <Grid item xs={12}>
                <Typography my={3}
                  textAlign="center"
                  variant="subtitle1"
                  color={theme.palette.primary.main}>Thank you for your interest in working with Veracity!</Typography>
              </Grid>

              <Grid item xs={12}>



                <TextField label="Project name" required fullWidth {...register('name', { required: true, })} />
                {errors && errors.name && <span>Error message</span>}
              </Grid>
              <Grid item xs={12}>

                <TextField label="Project url" required fullWidth {...register('website', { required: true, })} />
                {errors && errors.website && <span>Error message</span>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label="Company name" required fullWidth {...register('company',)}
                />
                {errors && errors.company && <span>Error message</span>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label="Email address" required fullWidth {...register('email',)}
                />
                {errors && errors.email && <span>Error message</span>}
              </Grid>

              <Grid item xs={12} sm={6}>



                <TextField label="Contact person" required fullWidth {...register('contactPerson', { required: true, })} />
                {errors && errors.contactPerson && <span>Error message</span>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label="Telegram username" required fullWidth {...register('tgUsername', { required: true, })}
                />
                {errors && errors.tgUsername && <span>Error message</span>}
              </Grid>

              <Grid item xs={12} mt={5} sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h6">What type of protocol is your project?</Typography>
                {protocolOptions.map((protocol) =>

                  <FormControlLabel control={<Checkbox value={protocol.value} onChange={(e) => handleProtocolChange(e)} />} label={protocol.title} {...register('protocol')} />
                )
                }
                {errors && errors.protocol && <span>Error message</span>}
              </Grid>
              <Grid item xs={12} mt={5} sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h6">Which network will your project be deployed on?</Typography>
                <RadioGroup

                  value={networkValue}
                  onChange={handleNetworkChange}
                >
                  {networkOptions.map((network) =>

                    <FormControlLabel value={network.value} control={<Radio {...register('network', { required: true, })} />} label={network.title} />
                  )
                  }
                </RadioGroup>
                {errors && errors.network && <span>Error message</span>}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Contracts</Typography>
                <FormHelperText sx={{ color: theme.palette.primary.main, mb: 1 }}>
                  NOTE! We will delete all requests without contracts
                </FormHelperText>
                <TextField multiline rows={3} label="Links to contracts" required fullWidth {...register('contracts', { required: true, })}
                />

                <FormHelperText sx={{ color: theme.palette.primary.main }}>
                  We prefer GitHub / GitLab repositories for tracking purposes, but we also accept testnet contracts. We do not accept ZIP files for security reasons. If your GitHub repository is private, please add us to the repo.

                </FormHelperText>
                {errors && errors.contracts && <span>Error message</span>}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" mb={1}>Is your project a fork of another project?</Typography>
                <RadioGroup
                  value={isAFork}
                  onChange={onIsAForkChange}
                  sx={{ flexDirection: "row" }}
                >
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />

                </RadioGroup>
              </Grid>
              {isAFork === "true" &&
                <Grid item xs={12}>

                  <TextField label="Forked repository" required fullWidth {...register('forkRepo', { required: true, })}
                  />

                  <FormHelperText sx={{ color: theme.palette.primary.main }}>
                    Please include the repository of the forked project
                  </FormHelperText>
                  {errors && errors.forkRepo && <span>Error message</span>}
                </Grid>
              }
              <Grid item xs={12}>
                <Typography variant="h6" mb={1}>Customised features</Typography>
                <TextField multiline rows={3} label="Customised features" fullWidth {...register('customisedFeatures')}
                />

                <FormHelperText sx={{ color: theme.palette.primary.main }}>
                  If your projects have any customised features please specify them in as much detail as possible
                </FormHelperText>
                {errors && errors.customisedFeatures && <span>Error message</span>}
              </Grid>
              <label
                htmlFor="got-ya"
                style={{
                  position: "absolute",
                  overflow: "hidden",
                  clip: "rect(0 0 0 0)",
                  height: "1px",
                  width: "1px",
                  margin: "-1px",
                  padding: "0",
                  border: "0",
                }}
              >
                Don’t fill this out if you're human:
                <input tabIndex="-1" {...register('got-ya')} />
              </label>

              <Grid xs={12} item>
                {submitting ?

                  <Typography textAlign="center">Submitting...</Typography>
                  : <Button variant="contained" type="submit">Submit</Button>
                }
              </Grid>

            </Grid>
          </form>
        }

      </Container>


    </Layout>)
};

export default Contact;
